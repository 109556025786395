<template>
	<div>
		<div class="flex header">
			<h2>角色管理</h2>
			<div class="flex">
				<el-input
						placeholder="请输入关键信息"
						prefix-icon="el-icon-search"
						v-model="search"
						@change="getData">
				</el-input>
				<el-button @click="addRole">新增角色</el-button>
			</div>
		</div>
		<div class="table">
			<el-table
					:data="tableData"
					:header-cell-style="{'background':'rgba(0, 103, 166, 0.3)','color': '#ffffff','fontWeight':'bold','textAlign':'center'}"
					height="calc(100vh - 248px)"
					style="width: 100%">
				<el-table-column
						prop="roleName" align="center" show-overflow-tooltip
						label="角色名称">
				</el-table-column>
				<el-table-column
						prop="roleCode" align="center" show-overflow-tooltip
						label="角色代码">
				</el-table-column>
				<el-table-column
						prop="remark" align="center" show-overflow-tooltip
						label="角色描述">
				</el-table-column>
				<!--<el-table-column
						prop="permissions" align="center" show-overflow-tooltip
						label="角色权限">
				</el-table-column>-->
				<el-table-column align="center"
												 label="操作" width="200px">
					<template slot-scope="scope">
						<el-button class="update" @click="handleEdit(scope.$index, scope.row)">角色修改</el-button>
						<el-button class="update" @click="handleDel(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
					class="pagination"
					@current-change="handleCurrentChange"
					:current-page="page.current"
					:page-size="page.size"
					layout="prev, pager, next, total"
					:total="page.total">
			</el-pagination>
		</div>
		<roleEdit :state="state" :showEditRole="showEditRole" :roleInfo="roleInfo" @openRole="editRole" :permissionList="permissionList" :permissionListPhone="permissionListPhone"></roleEdit>
	</div>

</template>

<script>
import { deleteAuthRole, getAuthRoleList,getPermissionList } from "@/api/api";

export default {
	name: "roleMana",
	data() {
		return {
			search: "",
			tableData: [],
			page: {
				current: 1,
				size: 10,
				total: 0
			},
			showEditRole: false,
			state: "新增",
			roleInfo: {
				roleName: "",
				remark: "",
				permissions: []
			},
			permissionList:[],
			permissionListPhone:[],
		};
	},
	mounted() {
		this.getData();
		this.getPermission();
		this.getPhonePermission();
	},
	methods: {
		getData() {
			let params = {
				page: this.page.current,
				size: this.page.size,
				key:this.search
			};
			getAuthRoleList(JSON.stringify(params)).then(res => {
				this.tableData = res.data.rows;
				this.page.total = res.data.total;
			}).catch(err => {
				console.log("err: " + err);
			});
		},
		// 切换page
		handleCurrentChange(val) {
			this.page.current = val;
			this.getData();
		},
		// 新增
		addRole() {
			this.showEditRole = true;
			this.state = "新增";
			this.roleInfo = {
				roleName: "",
				remark: "",
				permissions: []
			};
		},
		// 编辑用户
		handleEdit(index, row) {
			this.showEditRole = true;
			this.state = "编辑";
			this.roleInfo = row;
		},
		// 关闭弹窗
		editRole(obj) {
			this.showEditRole = false;
			if (!obj.flg) {
				this.getData();
			}
		},
		//查询权限
		getPermission(){
			getPermissionList(0).then(res=>{
				this.permissionList = res.data.data
			})
		},
		//查询权限
		getPhonePermission(){
			getPermissionList(1).then(res=>{
				this.permissionListPhone = res.data.data
			})
		},
		// 删除
		handleDel(id) {
			this.$confirm("此操作将永久删除角色，是否继续?", "提示", { type: "warning" })
					.then(() => { // 向服务端请求删除
						deleteAuthRole(id).then(() => {
							this.$message.success("删除成功");
							this.getData();
						}).catch(() => {
							this.$message.error("删除失败!");
						});
					}).catch(() => {
				this.$message.info("已取消操作!");
			});
		}
	},
	components: {
		"roleEdit": () => import("./roleEdit")
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/table";
</style>
